import React, { useEffect, useState } from 'react';
import styles from './CuponCode.module.scss';
import { Button, Form } from 'react-bootstrap';
import { useCart } from '../../redux/hooks';

export const CuponCode = () => {
  const { addDiscount, rmvDiscount, cuponDetails } = useCart();

  const [couponCode, setCouponCode] = useState('');
  const [validCoupon, setValidCoupon] = useState(false);
  const [invalidCoupon, setInvalidCoupon] = useState(false);

  const validCoupons = [
    { code: 'CHULLIPULLI', discount: 99 },
    { code: 'FIRST', discount: 20 },
    { code: 'KANPUR', discount: 30 },
  ];

  useEffect(() => {
    if (cuponDetails?.code) {
      setCouponCode(cuponDetails.code);
      setValidCoupon(true);
    }
  }, [cuponDetails]);

  const handleApplyCoupon = () => {
    const formattedCode = couponCode.trim().toUpperCase();
    const coupon = validCoupons.find(coupon => coupon.code === formattedCode);

    if (coupon) {
      setValidCoupon(true);
      setInvalidCoupon(false);
      addDiscount(coupon);
    } else {
      setValidCoupon(false);
      setInvalidCoupon(true);
    }
  };

  const handleRemoveCoupon = () => {
    setCouponCode('');
    setValidCoupon(false);
    setInvalidCoupon(false);
    rmvDiscount();
  };

  return (
    <div className={styles.cuponsBox}>
      <Form.Group controlId="couponCode">
        <Form.Control
          type="text"
          placeholder="Enter coupon code"
          value={couponCode}
          onChange={e => setCouponCode(e.target.value)}
          className={styles.cuponInput}
          isValid={validCoupon}
          isInvalid={invalidCoupon}
          disabled={validCoupon}
        />
        <Form.Control.Feedback
          className={styles.feedback}
          type="valid"
        ></Form.Control.Feedback>
        <Form.Control.Feedback
          className={styles.feedback}
          type="invalid"
        ></Form.Control.Feedback>
      </Form.Group>
      <button
        onClick={validCoupon ? handleRemoveCoupon : handleApplyCoupon}
        className={styles.cuponButton}
      >
        {validCoupon ? 'REMOVE' : 'APPLY'}
      </button>
    </div>
  );
};
