import React from 'react';
import styles from './InvoiceContainer.module.scss';
import { useCart } from '../../redux/hooks';
import { PrimaryButton } from '../Utility Components';
export const InvoiceContainer = ({
  onClick,
  form,
  type,
  buttonDisplay,
  loading,
  disable,
}) => {
  const { getTotalValue, cuponDetails } = useCart();
  return (
    <div className={styles.checkoutBox}>
      <div className={styles.totalInfo}>
        <div className={styles.chargeRow}>
          <div className={styles.chargeTitle}>Item Total</div>
          <div className={styles.chargedPrice}>₹{getTotalValue.totalPrice}</div>
        </div>
        <div className={styles.chargeRow}>
          <div className={styles.chargeTitle}>
            Discount
            {cuponDetails ? (
              <div
                style={{
                  marginLeft: '0.5rem',
                  color: '#206a5d',
                  fontWeight: 'bolder',
                }}
              >
                Additional {`(${cuponDetails?.discount}%)`}
              </div>
            ) : null}
          </div>
          <div className={styles.chargedPrice}>
            ₹{getTotalValue.totalPrice - getTotalValue.discountedPrice}
          </div>
        </div>
        <div className={styles.chargeRow}>
          <div className={styles.chargeTitle}>Delivery Fees</div>
          <div style={{ color: '#206a5d' }}>Free</div>
        </div>
        <div className={styles.grandTotal}>
          <div className={styles.chargeRow}>
            <div className={styles.chargeTitle}>Grand Total</div>
            <div className={styles.chargedPrice}>
              ₹{getTotalValue.discountedPrice}
            </div>
          </div>
          <div className={styles.taxesInfo}>Inclusive of all Taxes</div>
        </div>
        <div className={styles.deliveryTime}>
          Delivery Time : <b>3-5 days</b>
        </div>
        {!buttonDisplay && (
          <div className={styles.buttonContainer}>
            <PrimaryButton
              form={form}
              type={type}
              label="Continue"
              onClick={onClick}
              loading={loading}
              disable={loading}
            ></PrimaryButton>
          </div>
        )}
      </div>
    </div>
  );
};
