import React from 'react';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { useCart } from '../../redux/hooks';
import { useNavigate } from 'react-router';
import './Payment.css';
export const PaymentSuccess = () => {
  const { orderId, clearCartItems, clearUserOrderId } = useCart();

  let navigate = useNavigate();

  const onCompletePayment = () => {
    Swal.fire({
      title: 'Order Successful',
      html: `
        <div class="swal-custom-content">
          <img src="https://i.postimg.cc/vHKnMmm2/happy-bee.png" alt="Image" class="swal-custom-image-success">
          <div class="swal-custom-text">
            <p>Your order ID is ${orderId}. A confirmation will be sent to you shortly! Thank you for shopping with us!</p>
          </div>
        </div>
      `,
      icon: 'success',
      confirmButtonText: 'Back to the store!',
      showCloseButton: true,
    }).then(() => {
      clearCartItems();
      navigate('/');
    });

    clearUserOrderId();
  };

  useEffect(() => {
    onCompletePayment();
  }, []);

  return <div style={{ height: '80vh' }}></div>;
};
