import React, { useEffect, useState } from 'react';
import styles from '../../Styles/PaymentPage.module.scss';
import { InvoiceContainer } from '../InvoiceContainer/InvoiceContainer';
import { PaymentMethodFooter } from '../PaymentMethodsFooter/PaymentMethodFooter';
import card from '../../Assets/cardImg.svg';
import cod from '../../Assets/cash_ond.svg';
import axios from 'axios';
import { useCart, useUser } from '../../redux/hooks';
import { useNavigate } from 'react-router';
import { BackButton, PrimaryButton } from '../Utility Components';

export const PaymentPage = ({ flowState }) => {
  const { getTotalValue, orderId, rmvDiscount } = useCart();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    'pay_online'
  );

  useEffect(() => {
    if (!flowState.addressCompleted) {
      navigate('/address');
    }
  }, [flowState.addressCompleted, navigate]);

  const handlePaymentMethodSelect = method => {
    setSelectedPaymentMethod(method);
  };

  const paymentClick = async () => {
    if (selectedPaymentMethod === 'pay_online') {
      setLoading(true);
      const formattedPayload = {
        amount: getTotalValue.discountedPrice,
        mobileNumber: user.address.mobileNumber,
        email: user.address.emailId,
        orderId: orderId,
        name: user.address.firstName,
        payment_method: selectedPaymentMethod,
      };

      try {
        const response = await axios.post(
          'https://gardening-bee-service.vercel.app/api/payment',
          formattedPayload
        );

        setLoading(false);
        rmvDiscount();
        window.location.href = response.data;
      } catch (error) {
        console.error('Payment error: ', error);
        setLoading(false);
      }
    } else {
      setLoading(true);
      const formattedPayload = {
        amount: getTotalValue.discountedPrice,
        mobileNumber: user.address.mobileNumber,
        email: user.address.emailId,
        orderId: orderId,
        name: user.address.firstName,
        payment_method: selectedPaymentMethod,
      };

      try {
        const response = await axios.post(
          'https://gardening-bee-service.vercel.app/api/payment',
          formattedPayload
        );
        setLoading(false);
        rmvDiscount();
        navigate('/pay/success');
      } catch (error) {
        console.error('Payment error: ', error);
        setLoading(false);
        navigate('/pay/failure');
      }
    }
  };

  return (
    <div className={styles.paymentWrapper}>
      <div className={styles.mobileHeader}>
        <div className="flex-row">
          <BackButton />
          <div className="font-bold f-16">Address</div>
        </div>
        <div className={styles.stepInfo}>Step 3/3</div>
      </div>
      <div className={styles.checkoutWrapper}>
        <div className={styles.leftSection}>
          <div className={styles.billingInformation}>
            <div className={styles.cartInfo}>Choose payment mode</div>
          </div>
          <div className={styles.paymentInfo}>
            <div className={`${styles.paymentOptions} ${styles.desktopHidden}`}>
              <div
                className={`${styles.paymentOption} ${
                  selectedPaymentMethod === 'pay_online'
                    ? styles.paymentActive
                    : ''
                }`}
                onClick={() => handlePaymentMethodSelect('pay_online')}
              >
                <img alt="pay online" src={card} />
                <div className="px-1 mx-1 font-bold">Online Payment</div>
              </div>
              <div
                className={`${styles.paymentOption} ${
                  selectedPaymentMethod === 'cod' ? styles.paymentActive : ''
                }`}
                onClick={() => handlePaymentMethodSelect('cod')}
              >
                <img alt="Cash on delivery" src={cod} />
                <div className="px-1 mx-1 font-bold">Cash on delivery</div>
              </div>
            </div>
            <div className={styles.paymentSpecifics}>
              <div
                onClick={() => handlePaymentMethodSelect('pay_online')}
                className={`${styles.paymentBox} ${
                  selectedPaymentMethod === 'cod' ? styles.desktopHidden : ''
                }`}
              >
                <div className={styles.desktopHidden}>
                  <img alt="pay online" src={card} />
                </div>

                <div className={styles.payCardContent}>
                  <div className={styles.paymentHeader}>Pay Online</div>
                  <div className={styles.paymentContent}>
                    Use credit/debit card, net-banking, UPI, wallets to complete
                    the payment.
                  </div>
                  <div className={styles.mobileHidden}>
                    <PrimaryButton
                      onClick={paymentClick}
                      loading={loading}
                      disable={loading}
                      label={`Pay ₹${getTotalValue.discountedPrice}`}
                    />
                  </div>
                </div>
                <div className={styles.desktopHiddenRadio}>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="pay_online"
                    checked={selectedPaymentMethod === 'pay_online'}
                    onClick={e => e.stopPropagation()}
                    onChange={() => handlePaymentMethodSelect('pay_online')}
                  />
                </div>
              </div>

              <div
                onClick={() => handlePaymentMethodSelect('cod')}
                className={`${styles.paymentBox} ${
                  selectedPaymentMethod === 'pay_online'
                    ? styles.desktopHidden
                    : ''
                }`}
              >
                <div className={styles.desktopHidden}>
                  <img alt="Cash on delivery" src={cod} />
                </div>

                <div className={styles.payCardContent}>
                  <div className={styles.paymentHeader}>Cash on delivery</div>
                  <div className={styles.paymentContent}>
                    Pay in cash or pay in person at the time of delivery with
                    GPay/PayTM/PhonePe.
                  </div>
                  <div className={styles.mobileHidden}>
                    <PrimaryButton
                      onClick={paymentClick}
                      loading={loading}
                      disable={loading}
                      label="Place Order"
                    />
                  </div>
                </div>
                <div className={styles.desktopHiddenRadio}>
                  <input
                    type="radio"
                    name="paymentMethod"
                    checked={selectedPaymentMethod === 'cod'}
                    value="cod"
                    onChange={() => handlePaymentMethodSelect('cod')}
                    onClick={e => e.stopPropagation()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.rightSection} id="pricedetails">
          <InvoiceContainer buttonDisplay={true} />
        </div>
      </div>
      <div className={styles.mobileCheckout}>
        <div className={styles.mobileLeft}>
          <div className={styles.price}>₹{getTotalValue.discountedPrice}</div>
          <a href="#pricedetails" className={styles.viewPriceDetails}>
            View price details
          </a>
        </div>
        <div className={styles.mobileRight}>
          <PrimaryButton
            loading={loading}
            disable={loading}
            onClick={paymentClick}
            label={` ${
              selectedPaymentMethod === 'pay_online'
                ? `Pay ₹${getTotalValue.discountedPrice}`
                : 'Place Order'
            }`}
          />
        </div>
      </div>
      <PaymentMethodFooter />
    </div>
  );
};
