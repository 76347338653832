import { GlobalHeader } from './Components/GlobalHeader';
import './Styles/globals.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import './App.css';
import './index.css';
import { MainPage } from './Components/MainPage';
import { Divider } from './Components/Divider/Divider';
import { GlobalFooter } from './Components/GlobalFooter';
import { Cart } from './Components/Cart';
import { AddressForm } from './Components/AddressForm';
import ReactGA from 'react-ga4';
import { useEffect, useState } from 'react';
import { ProductPage } from './Components/ProductPage';
import { PinCodeModal } from './Components/Utility Components';
import { PaymentFailure, PaymentPage, PaymentSuccess } from './Components/Payment';
import { MobileMenu } from './Components/Mobile Screens';
import { PrivacyPolicyPage, RefundPage, TnCPage } from './Components/Compliance Pages';

ReactGA.initialize('G-TS86RFW7KT');

function App() {
  ReactGA.send({
    hitType: 'pageview',
    page: '/',
    title: 'Home',
  });

  const [flowState, setFlowState] = useState({
    cartCompleted: false,
    addressCompleted: false,
  });

  useEffect(() => {}, []);

  return (
    <Router>
      {/* uncomment/comment the below code to close/open the store */}
      {/* <StoreClosedModal /> */}
      <PinCodeModal />
      <GlobalHeader />
      <Routes>
        <Route path="/" exact Component={MainPage}></Route>
        <Route exact path="/privacy-policy" Component={PrivacyPolicyPage} />
        <Route exact path="/refund-policy" Component={RefundPage} />
        <Route exact path="/terms-and-conditions" Component={TnCPage} />
        <Route exact path="/pay/success" element={<PaymentSuccess />} />
        <Route exact path="/pay/failure" element={<PaymentFailure />} />
        <Route
          exact
          path="/cart"
          element={<Cart setFlowState={setFlowState} />}
        />

        <Route
          exact
          path="/address"
          element={
            <AddressForm setFlowState={setFlowState} flowState={flowState} />
          }
        />
        <Route
          exact
          path="/pay"
          element={<PaymentPage flowState={flowState} />}
        />
        <Route path="/product/:productId" element={<ProductPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Divider></Divider>
      <GlobalFooter />
      <MobileMenu />
    </Router>
  );
}

export default App;
